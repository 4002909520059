/* Masonry Grid 容器 */
.masonry-grid {
    display: flex;
    flex-direction: row;
    gap: 100px
}

/* Masonry Item 子项 */
.masonry-item {
    flex: 1 1 calc(50% - 1rem); /* 每个子项占据 50% 的宽度，减去间隙 */
    margin-bottom: 30px;
}

/* 响应式调整列数 */
@media (max-width: 768px) {
    .masonry-item {
        flex: 1 1 100%; /* 小屏幕下每行只显示 1 个元素 */
    }
}
