/* 导航栏基础样式 */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    z-index: 9999999;
    transition: background-color 0.3s ease, color 0.3s ease, top 0.3s ease-in-out;
}


/* 深色模式样式 */
.darkMode {
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.9),
            rgba(0, 0, 0, 0.3)
    );
    color: #ffffff;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

/* 浅色模式样式 */
.lightMode {
    background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.3)
    );
    color: #000000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* 链接样式 */
.link {
    text-decoration: none;
    font-size: 1rem;
    color: inherit;
    transition: color 0.3s ease;
}

.link:hover {
    transform: scale(1.1);
}

/* 链接颜色 */
.lightMode .link {
    color: #000000;
}

.darkMode .link {
    color: #ffffff;
}

.lightMode .link:hover {
    color: #007bff; /* 鼠标悬停效果 */
}

.darkMode .link:hover{
    color: #007bff; /* 鼠标悬停效果 */
}

/* 按钮样式 */
button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

button:hover {
    transform: scale(1.1);
}

/* 主题切换按钮样式 */
.themeToggle {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: inherit; /* 跟随主题字体颜色 */
    outline: none;
    transition: transform 0.3s ease;
}

.themeToggle:hover {
    transform: scale(1.2); /* 鼠标悬停时放大效果 */
}



.navbar-toggler {
    background-color: #000000; /* 透明背景 */
    border: none; /* 去掉默认边框 */
    padding: 0.5rem; /* 控制间距 */
}

.navbar-toggler:hover {
    background-color: #007bff; /* 鼠标悬停时的背景色 */
    transform: scale(1.1); /* 添加悬停动画 */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.navbar-toggler-icon {
    background-image: none; /* 移除默认图标 */
    content: ""; /* 自定义图标样式 */
    width: 20px;
    height: 2px;
    background-color: #000; /* 自定义图标颜色 */
    display: block;
    position: relative;
}

.navbar-toggler-icon::after,
.navbar-toggler-icon::before {
    content: "";
    width: 20px;
    height: 2px;
    background-color: #000;
    position: absolute;
    left: 0;
}

.navbar-toggler-icon::before {
    top: -6px; /* 上部横线 */
}

.navbar-toggler-icon::after {
    top: 6px; /* 下部横线 */
}


/* 以下是全屏nav的样式控制
 --------------------------------------------*/
.fullScreenNav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.link {
    color: inherit;
    font-size: 2rem;
    margin: 1rem;
    text-align: center;
}

/* 假设 styles.fullScreenNav 是全屏菜单的容器 */
.fullScreenNav {
    position: relative;
    /* 其他样式 */
}

/* 关闭按钮样式 */
.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 2rem;
    color: inherit; /* 或根据背景颜色设置关闭按钮颜色 */
}

/*搜索栏
----------*/
.container {
    position: fixed;
    bottom: 0;
    top:100px;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    height: 20px;
}

.input {
    padding: 10px;
    font-size: 1rem;
    width: 300px
}
.button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}


