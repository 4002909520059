/* 基本布局 */
.nav {
    background-color: transparent;
    display: flex;
    align-items: center;
    width: auto;
}

/* 无序列表样式 */
.navList {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

/* 列表项样式 */
.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}

/* 链接样式 */
.navLink {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: inherit;
    border-radius: 8px;
    border: 2px dashed transparent;
    transition: all 0.3s ease-in-out;
}

/* 鼠标悬停样式 */
.navLink:hover {
    transform: scale(1.2);
    border-color: inherit;
}

/* 图片样式 */
.iconStyle {
    object-fit: contain;
    color: inherit;
}

/* 响应式设计 */
@media (max-width: 768px) {
    .nav {
        padding: 10px;
    }

    .navList {
        gap: 15px;
    }

    .iconStyle {
        width: 30px;
        height: 30px;
    }
}