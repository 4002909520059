* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px; /* 设置基础字体大小 */
}

*::before,
*::after {
  box-sizing: border-box;
}


html, body{
  width: 100vw;
  height: 100vh;
  position: absolute;
  font-family: "Arial", sans-serif;
  scroll-behavior: smooth;
  background-color: #ffffff;
  line-height: 1;  /* 统一行高 */
  font-weight: normal;  /* 统一字体粗细 */
}

html{
  font-size: 16px
}

body {
  font-size: 16px;
}


.App {
  text-align: center;
  width: 100vw;
  height: 100vh; /* 确保主应用占满视口 */
  display: flex;
  flex-direction: column;
}

