html, body {
  margin: 0;
  padding: 0;
  top: 0;
  scroll-behavior: smooth;
}

#root {
  width: 100vw;
  margin: 0;
  padding: 0;
  top: 0;
  display: flex; /* 设置为flex布局 */
  flex-direction: column; /* 纵向排列 */
}






